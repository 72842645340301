import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Zoom from "@mui/material/Zoom";
import { useNavigate } from "react-router-dom";


const User = ({ socket }) => {
  const navigate = useNavigate();

  const handleLeaveChat = () => {
    localStorage.removeItem("userName");
    navigate("/");
    window.location.reload();
  };
  const [users, setUsers] = useState([]);
  useEffect(() => {
    socket.on("newUserResponse", (data) => setUsers(data));
  }, [socket, users]);

  return (
    <div>
      <header className="__mainHeader">
        <p>Mabsout</p>
        <button className="leave__btn" onClick={handleLeaveChat}>
          LEAVE
        </button>
      </header>
      <div className="chat__sidebar">
        <div>
          <h4 className="chat__header">ACTIVE USERS</h4>
          <div className="online__users">
            {users.map((user) => (
              <Zoom in={true} style={{ transitionDelay: "500ms" }}>
                <Card
                  className="card"
                  key={user.socketID}
                  sx={{ minWidth: 275 }}
                >
                  <CardContent className="card__content">
                    <Typography
                      className="emj"
                      sx={{ fontSize: 100 }}
                      gutterBottom
                    >
                      {user.em}
                    </Typography>
                    <Typography
                      className="emj"
                      key={user.socketID}
                      variant="h4"
                      component="div"
                    >
                      {user.userName}
                    </Typography>
                  </CardContent>
                </Card>
              </Zoom>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default User;
