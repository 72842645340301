import React, {useState} from 'react'
import {useNavigate} from "react-router-dom"
import Picker from 'emoji-picker-react';


const Home = ({socket}) => {
    const navigate = useNavigate()
    const [userName, setUserName] = useState("")
    const [enable, setEnable] = useState(true)
    const [team, setTeam] = useState("")
    const [chosenEmoji, setChosenEmoji] = useState(null);

    const onEmojiClick = (event, emojiObject) => {
      setChosenEmoji(emojiObject);
      setEnable(false)

    };
    const handleSubmit = (e) => {
      const em=chosenEmoji.emoji
        e.preventDefault()
        localStorage.setItem("userName", userName)
        socket.emit("newUser", {userName, socketID: socket.id,em})
        navigate("/users")
    }
   
  return (
    <form className='home__container' onSubmit={handleSubmit}>
        <h2 className='home__header'>Write your name and select emoji to join</h2>
        <label htmlFor="username">Username</label>
        <input type="text" 
        minLength={4} 
        name="username" 
        id='username'
        className='username__input' 
        value={userName} 
        onChange={e => setUserName(e.target.value)}
        />
           <div>
      <Picker onEmojiClick={onEmojiClick} />
    </div>
        <button disabled={enable} className='home__cta'>JOIN</button>
    </form>
  )
}

export default Home